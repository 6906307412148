const industries = [
  "",
  "Banking / Trust",
  "Business Services / Consulting",
  "Data Processing Services",
  "Education",
  "Financial Services",
  "Healthcare",
  "Insurance",
  "Life Sciences / Pharma",
  "Manufacturing",
  "Media / Advertising",
  "Medicare / Medicaid",
  "Retail / Wholesale",
  "SEC",
  "Software / Technology",
  "State or Local Government",
  "Telecommunications",
  "Transportation",
  "Utilities & Energy",
];

export default industries;
